.size-selector {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: fixed;
  right: 0.5em;
  top: 0.5em;
  z-index: 999;
  li {
    display: inline-block;
    padding: 0 0.25em;
    cursor: pointer;
    &.selected {
      background-color: #eee;
      border-radius: 0.25em;
    }
  }
}
.size-icon {
  width: 32px;
  vertical-align: middle;
  &[alt="s"] {
    max-width: 16px;
  }
  &[alt="m"] {
    max-width: 24px;
  }
  &[alt="l"] {
    max-width: 32px;
  }
}
