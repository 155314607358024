.header {
  height: 3em;
  line-height: 3em;
  width: 100%;
  background-color: #fff;
  color: #38a1f3;
  box-shadow: 0 0 0.2em 0 #aaa;
  &-logo {
    width: 2em;
    margin-left: 2em;
    display: inline-block;
    vertical-align: middle;
  }
  h1 {
    text-indent: 1em;
    font-size: 1.5em;
    margin: 0;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
}
@media (max-width: 425px) {
  .size-selector {
    display: none;
  }
  //CSS
}
