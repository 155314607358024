.App {
  position: relative;
}
.content {
  min-height: 90vh;
}
.top-ad-container,
.bottom-ad-container {
  // width: 100%;
  // min-height: 200px;
  background-color: transparent;
  margin-top: 10px;
  margin-bottom: 10px;
}

.scrollup-icon {
  width: 48px;
}
.load-new {
  &-container {
    width: 100%;

    text-align: center;
  }
  &-button {
    border-bottom: 1px solid #b3e5fc;
    background-color: #e3f2fd;
    color: #0a61a4;
    padding: 0.5em;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: #e1f5fe;
    }
    &:focus {
      outline: none;
    }
  }
}
.app-spinner-container {
  margin: 1em auto;
  width: 100px;
  height: 100px;
}
