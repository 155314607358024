.picture-page {
  margin: 1em;
  clear: both;
  overflow: hidden;
}
.gallery-container {
  min-height: 70vh;
}
.tool-bar {
  text-align: center;
  margin-top: 10vh;
  &-loading-container {
    margin: 1em auto;
    width: 50px;
    height: 50px;
  }
}
.load-more {
  font-size: 1.5em;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  background-color: #fff;
  color: #38a1f3;
  border: 1px solid #38a1f3;
  font-weight: 300;
  cursor: pointer;
  width: 70%;

  &:focus {
    outline: none;
    border: 1px solid #777;
  }
}
