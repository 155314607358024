footer {
  height: auto;
  margin-top: 5em;
  background-color: #e0e0e0;
  color: #212121;
  padding: 1em;
}
.about,
.attribution,
.terms {
  max-width: 20em;
  font-size: 0.9em;
  display: inline-block;
  vertical-align: top;
  margin-right: 2em;
  h2 {
    font-weight: normal;
    font-size: 1em;
  }
  a {
    text-decoration: none;
  }
  p {
    line-height: 1.3em;
  }
  p,
  ul {
    font-weight: 200;
  }
  ul {
    padding-left: 1em;
  }
  li {
    padding-bottom: 0.5em;
  }
}
