.share-list {
  list-style-type: none;
  margin: 0.5em;
  padding: 0;
  position: fixed;
  right: 5px;
  bottom: 0;
}
.SocialMediaShareButton {
  display: inline-block;
  margin: 0.25em;
  cursor: pointer;
}
