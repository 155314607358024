.profile-pic {
  background: #ddd;
  box-shadow: 1px 1px 1px 0px #999;
  border-radius: 0.5em;
  overflow: hidden;
  img {
    width: 100%;
    background-image: url("../../svg/animated-logo.svg");
    background-size: 50%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .pic-attr {
    font-size: 0.8em;
    margin: 0;
    bottom: 0;
    padding: 0.5em;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0.5em;
    position: absolute;
    visibility: hidden;
  }
  &:hover .pic-attr,
  &:active .pic-attr,
  &:focus .pic-attr {
    visibility: visible;
  }
  a {
    color: #fff;
  }
}
